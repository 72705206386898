.slick-slider {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 32px 24px 32px;
  -webkit-font-smoothing: "none";
  box-shadow: none;
}
.custom-prevArrow {
  left: 10px;
}
.custom-nextArrow {
  right: 10px;
}
